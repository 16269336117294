.App {
  text-align: center;
}

body {
  background-color: black;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.banner-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.banner {
  max-width: 100%;
  max-height: 150px;
}

.comicPageWrapper {
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}

.comicPageContainer {
  width: 55%;
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  justify-content: center;
}

@media only screen and (max-width: 1200px) {
  .comicPageContainer {
    width: 100%;
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.comicPageImageContainer {
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  padding: 152.938% 0 0 0; /* 34.37% = 100 / (w / h) = 100 / (640 / 220) */
}

.comicPageParent {
  display: flex;
  justify-content: center;
  width: 100%;
}

.comicNav {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.comicPage {
  display: block;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.comic-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}

.comic-cover {
  max-height: 430px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: white;
}
